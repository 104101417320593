
var OnScrollAllVendorsListLastEndReached = null;

function OnScrollAllVendorsList() {

    var el = $('#allVendorsList');

    if (el.scrollTop() + el.innerHeight() >= el[0].scrollHeight) {

        var triggerReachedEnd = false;

        if (OnScrollAllVendorsListLastEndReached == null) {
            OnScrollAllVendorsListLastEndReached = new Date();
            DotNet.invokeMethodAsync('VisitForm.Portal.Common.UI', 'OnAllVendorListScrolledToEnd', 0, 0);
        }
        else {

            var secondsSinceLastEndReached = Math.abs((new Date).getTime() - OnScrollAllVendorsListLastEndReached.getTime()) / 1000;
            if (secondsSinceLastEndReached > 2) {
                DotNet.invokeMethodAsync('VisitForm.Portal.Common.UI', 'OnAllVendorListScrolledToEnd', 0, 0);
                OnScrollAllVendorsListLastEndReached = new Date();
            }
        }

    }
 
}